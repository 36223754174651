<template>
    <el-main>
        <el-form class="el-form-search" label-width="100px">
            <el-form-item label="所属小区">
                <el-select size="small" v-model="form.community_id" placeholder="请选择">
                    <el-option v-for="item in community" :key="item.id" :label="item.community_name"
                        :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="技工" >
                   <el-select size="small" v-model="form.artisan_id" placeholder="请选择或搜索" filterable>
                    <el-option v-for="item in artisan" :key="item.id" :label="item.artisan_name"
                        :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="产生时间">
                <el-date-picker @change="getTime" v-model="form.create_time" size="small" type="daterange" range-separator="~"
                    start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
            </el-form-item>
            <el-form-item label="关联单号">
                <el-input size="small" v-model="form.order_sn"></el-input>
            </el-form-item>
            <el-form-item label="结算状态">
                <el-select size="small" v-model="form.status" placeholder="请选择">
                    <el-option label="待结算" value="1"></el-option>
                    <el-option label="已结算" value="2"></el-option>
                    <el-option label="已退款" value="3"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label=" " label-width="25px">
                <el-button type="primary" @click="search" size="small">搜索</el-button>
                <el-button type="primary" @click="exportData" size="small">导出</el-button>
                <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
            </el-form-item>
        </el-form>
        <div class="descriptions">
            <div class="descriptions_item">筛选后订单金额：￥{{info.total_price}}</div>
            <div class="descriptions_item">筛选后订单数量：￥{{info.total}}</div>
            <div class="descriptions_item">筛选后平台抽佣金额：￥{{info.platform_price}}</div>
            <div class="descriptions_item">筛选后技工佣金金额：￥{{info.artisan_price}}</div>
        </div>
        <el-table :data="list" row-key="id" default-expand-all :header-cell-style="{ 'background-color': '#F8F9FA' }"
            :tree-props="{ children: '_child', hasChildren: 'hasChildren' }">
            <template slot="empty">
                <No />
            </template>
            <el-table-column prop="ordersn" label="关联单号" align="center"> 
            </el-table-column>
            <el-table-column prop="user_mobile" label="下单用户账号" align="center"> </el-table-column>
            <el-table-column label="下单用户身份" align="center">
                <template slot-scope="scope">  
                     {{ scope.row.order.is_vip?'Vip':'非Vip' }}
                </template>
            </el-table-column>
            <el-table-column prop="community_name" label="所属小区" align="center" width="140"></el-table-column>
            <el-table-column prop="artisan_mobile" label="关联技工注册账号" align="center" width="140"></el-table-column>
            <el-table-column prop="artisan.artisan_name" label="技工姓名" align="center"></el-table-column>
            <el-table-column prop="artisan.mobile" label="联系电话" align="center"></el-table-column>
            <el-table-column prop="platform_rate" label="平台佣金" align="center" width="140">
                <template slot-scope="scope">  
                     {{ scope.row.platform_rate }}%
                </template>
            </el-table-column>
            <el-table-column prop="rate" label="结算比例" align="center">
                <template slot-scope="scope">  
                     {{ scope.row.rate }}%
                </template>
           </el-table-column>
            <el-table-column prop="order.total_price" label="服务总金额" align="center">
            </el-table-column>
            <el-table-column label="订单支付金额" align="center">
                <template slot-scope="scope">  
                     {{ scope.row.pay_amout }}
                </template>
            </el-table-column>
            <el-table-column prop="order.final_credit_price" label="余额支付金额" align="center"></el-table-column>
            <el-table-column label="是否折扣券" align="center">
                <template slot-scope="scope">  
                     {{ scope.row.order.fianl_reduce_price>0?'是':否 }}
                </template>
            </el-table-column>
            <el-table-column prop="order.fianl_reduce_price" label="折扣金额" align="center"></el-table-column>
            <el-table-column prop="num" label="技工佣金金额" align="center" width="140"></el-table-column>
            <el-table-column prop="platform_money" label="平台佣金金额" align="center" width="140">
            </el-table-column>
            <el-table-column label="订单状态" align="center">
                     <template slot-scope="scope">  {{
                      scope.row.order.status == 0
                      ? '已关闭'
                      : scope.row.order.status == -10
                          ? '已关闭'
                          : scope.row.order.status == -20
                              ? '已关闭'
                              : scope.row.order.status == 10
                                  ? '待付款'
                                  : scope.row.order.status == 20
                                      ? '待接单'
                                      : scope.row.order.status == 30 || scope.row.order.status == 40
                                          ? '进行中'
                                          : scope.row.order.status == 50
                                              ? '待确认'
                                              : scope.row.order.status == 60
                                                  ? '待签字'
                                                  : scope.row.order.status == 70 || scope.row.order.status == 80
                                                      ? '已完成'
                                                      : '--'
                  }}</template>
            </el-table-column>
            <el-table-column  label="结算状态" align="center">
                <template slot-scope="scope">  
                     {{ scope.row.status == 1 ? '待结算' : scope.row.status == 2 ? '未结算' : scope.row.status == 3 ? '已退回' :'--' }}
                </template>
            </el-table-column>
            <el-table-column label="订单时间" align="center">
                <template slot-scope="scope">{{ getDateformat(scope.row.create_time) }}</template>
            </el-table-column>
        </el-table>
        <Paging :total="total_number" :page="form.page" :pageNum="form.rows" @updatePageNum="updateData"></Paging>
    </el-main>
</template>

<script>
import config from '@/util/config';
const industryName = '/Gateway'; //行业版本接口名称（用于更换重复接口）
import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate';
export default {
    components: {
        Paging,
    },
    data () {
        return {
            showOptions: [
                {
                    value: 0,
                    label: '全部',
                },
                {
                    value: 1,
                    label: '否',
                },
                {
                    value: 2,
                    label: '是',
                },
            ],
            is_show: 0,
            name: '',
            community:[],
            artisan:[],
            list: [],
            total_number:0,
            info:[],
            dialogVisible: false,
            form: {
                page: 1,
                rows: 10,
                create_time:[],
                status:''
            }
        };
    },
    created () {
        this.getList();
        this.getCommunityList();
        this.getartisan();
    },
    methods: {
        getDateformat,
        getList () {
            let data = this.form
            if(this.form.is_export){
               this.form.is_export = '';
            }
            if (this.form.create_time && this.form.create_time.length) {
                data.start_time = this.form.create_time[0] / 1000;
                data.end_time = this.form.create_time[1] / 1000;
              //  delete(data.create_time)
            }
            this.$axios
                .post(this.$api.service.orderFinanceIncome, data)
                .then(res => {
                  if (res.code == 0) {
                      let list = res.result.list;
                      this.total_number = res.result.total;
                      this.list = list;
                      this.info = res.result
                    } else {
                      this.$message.error(res.msg);
                    }
                });
        },
        updateData(val, status) {
            if (status == 0) {
                this.form.rows = val;
              } else {
                this.form.page = val;
            }
            this.getList();
        },
        getCommunityList () {
            this.$axios.post(this.$api.service.communityList).then(res => {
                if (res.code === 0) {
                    this.community = res.result.list
                }
            });
        },
        getartisan(){
            let data = {type:2};
            data.page = 1;
            data.rows = 10000;
            this.$axios.post(this.$api.service.artisanList,data).then(res => {
                if (res.code === 0) {
                    this.artisan = res.result.list
                }
            });
        },
        exportData(){
            let data = this.form
            data.is_export=1
            if (this.form.create_time && this.form.create_time.length) {
                data.start_time = this.form.create_time[0] / 1000;
                data.end_time = this.form.create_time[1] / 1000;
            }
            this.$axios.post(this.$api.service.orderFinanceIncome, data).then(res => {
                if (res.code == 0) {
                    let path = res.result;
                    let a = document.createElement('a');
                    a.href = path;
                    a.id = 'download';
                    document.body.appendChild(a);
                    a.click();
                    let aDom = document.getElementById('download');
                    document.body.removeChild(aDom);
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        exportGoodsClass () {
            this.$axios.post(this.$api.goods.exportGoodsClass).then(res => {
                if (res.code == 0) {
                    let path = config.baseurl + '/' + res.result;
                    let a = document.createElement('a');
                    a.href = path;
                    a.id = 'download';
                    document.body.appendChild(a);
                    a.click();
                    let aDom = document.getElementById('download');
                    document.body.removeChild(aDom);
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        showValChange (val) {
            this.is_show = Number(val);
        },
        //搜索
        search () {
           this.getList()
        },
        cancelSearch () {
            this.form.page = 1;
            this.form.rows = 10;
            this.form.status = '';
            this.form.order_sn = '';
            this.form.community_id = '';
            this.form.artisan_id = '';
            this.form.create_time = [];
            this.form.is_export = '';
            this.getList()
        },
    },
};
</script>

<style lang="less" scoped>
.el-main {
    background: #fff;

    .el-table {
        flex: 1;

        /deep/ .el-input__inner {
            border-color: #fff;
        }

        /deep/ .el-input__inner:focus {
            border-color: #409eff;
        }

        border-top: 1px solid #ebeef5;
        border-left: 1px solid #ebeef5;
        border-right: 1px solid #ebeef5;
    }

    .edit {
        display: flex;
        align-items: center;

        span {
            margin-right: 5px;
        }

        i {
            font-size: 17px;
        }
    }

    .move .item {
        display: flex;
        align-items: center;
        padding-left: 30px;
        margin-bottom: 20px;
    }
}

/deep/ .el-table__expand-icon--expanded {
    // 这是点击后的旋转角度
    transform: rotate(0deg);
}

/deep/.el-table__expand-icon {
    .el-icon-arrow-right:before {
        //这是收起图标
        content: '\e723';
        font-size: 16px;
    }
}

/deep/ .el-table__expand-icon--expanded {
    .el-icon-arrow-right:before {
        // 这是展开图标
        content: '\e722';
        font-size: 16px;
    }
}

.descriptions {
    font-size: 18px;
    font-weight: bold;
    padding: 30px 0;
    display: flex;

    .descriptions_item+.descriptions_item {
        margin-left: 50px;
    }
}
</style>
